import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


const [isLoading, setIsLoading] = useState(false);

const handleLogin = async (e) => {
  e.preventDefault();
  setIsLoading(true);
  try {
    const response = await axios.post(`${backendUrl}/login/`, { username, password });
    if (response.status === 200 && response.data.message === "Login successful") {
      localStorage.setItem('authToken', response.data.token);
      localStorage.setItem('userRole', response.data.role);
      history.push('/dashboard');
    } else {
      setError('Invalid credentials');
    }
  } catch (error) {
    setError('Login failed. Please try again.');
    console.error(error);
  } finally {
    setIsLoading(false);
  }
};

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2></h2>
        {error && <p className="error-message">{error}</p>}
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
