// frontend/src/components/PrivateRoute.js

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Assuming you're storing the user's authentication status in localStorage or some global state
const isAuthenticated = () => {
  return !!localStorage.getItem('authToken'); // Or however you're storing the auth token
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" /> // Redirect to login if not authenticated
        )
      }
    />
  );
};

export default PrivateRoute;
