// frontend/src/components/Bets.js

import React from 'react';
import { useHistory } from 'react-router-dom';
import './Bets.css';
import logo from '../assets/EnterpriseLogo.png';
import BetsTable from './BetsTable';  // Import BetsTable component

const Bets = () => {
  const history = useHistory();

  const handleNavigation = (path) => {
    history.push(path); // Navigate to the specified path
  };

  const handleLogout = () => {
    history.push('/logout'); // Redirect to login page after logout
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <img className="dashboard-logo" src={logo} alt="Logo" />
        <h1>The Enterprise</h1>
      </div>
      <div className="dashboard-nav">
        <button className="nav-button" onClick={() => handleNavigation('/dashboard')}>
          Home
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/accounts')}>
          Accounts
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/wallets')}>
          Wallets
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/transactions')}>
          Transactions
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/bets')}>
          Bets
        </button>
        <button className="nav-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <hr className="dashboard-line" />

      {/* Render BetsTable below the navigation buttons */}
      <div className="bets-content">
        <BetsTable /> {/* Bets table component */}
      </div>
    </div>
  );
};

export default Bets;
