import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    const logout = async () => {
      try {
        // Call the backend to perform logout
        await axios.post(`${backendUrl}/logout/`);

        // Clear the auth token or session data on the client side
        localStorage.removeItem('authToken'); // Or any token or session data you store

        // Redirect to the login page
        history.push('/login');
      } catch (error) {
        console.error('Error logging out:', error);
        // You could also handle error, for example, show an alert or message
      }
    };

    logout();
  }, [history]);

  return (
    <div>
      <h2>Logging out...</h2>
    </div>
  );
};

export default Logout;
