// frontend/src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Accounts from './components/Accounts'; // Import your Accounts component
import Wallets from './components/Wallets'; // Import your Wallets component
import Transactions from './components/Transactions'; // Import your Transactions component
import Bets from './components/Bets'; // Import your Bets component
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute component
import Logout from './components/Logout';


function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          {/* Public route */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />

          {/* Protected routes */}
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/accounts" component={Accounts} />
          <PrivateRoute path="/wallets" component={Wallets} />
          <PrivateRoute path="/transactions" component={Transactions} />
          <PrivateRoute path="/bets" component={Bets} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
