import React, { useState } from 'react';
import './TransactionsModal.css';
import './Notification.css';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TransactionsModal = ({ isOpen, onClose }) => {
  const [transactionId, setTransactionId] = useState('');
  const [amount, setAmount] = useState('');
  const [type, setType] = useState('Deposit');
  const [source, setSource] = useState('Wasabi'); // Default value for source
  const [walletAddress, setWalletAddress] = useState('');
  const [notification, setNotification] = useState({ message: '', show: false });

  const resetForm = () => {
    setTransactionId('');
    setAmount('');
    setType('Deposit');
    setSource('Wasabi');
    setWalletAddress('');
  };

  const handleSave = async () => {
    const payload = {
      tx_id: transactionId,
      amount: parseFloat(amount),
      type: type,
      source: source,
      wallet_address: walletAddress,
    };

    try {
      const response = await fetch(`${backendUrl}/wallet/transactions/pending/ammend`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.ok) {
        setNotification({ message: 'Transaction saved successfully!', show: true });
        setTimeout(() => setNotification({ ...notification, show: false }), 10000);
        resetForm(); // Reset the form fields
        onClose(); // Optionally close the modal after saving
      } else {
        setNotification({ message: `Error: ${data.error}`, show: true });
        setTimeout(() => setNotification({ ...notification, show: false }), 10000);
      }
    } catch (error) {
      setNotification({ message: `Error: ${error.message}`, show: true });
      setTimeout(() => setNotification({ ...notification, show: false }), 10000);
    }
  };

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Add Transaction</h2>
        <div className="form-group">
          <label>Transaction Id:</label>
          <input type="text" value={transactionId} onChange={(e) => setTransactionId(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Amount:</label>
          <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Source:</label>
          <select value={source} onChange={(e) => setSource(e.target.value)}>
            <option value="Wasabi">Wasabi</option>
            <option value="Pending">Pending</option>
          </select>
        </div>
        <div className="form-group">
          <label>Type:</label>
          <select value={type} onChange={(e) => setType(e.target.value)}>
            <option value="Deposit">Deposit</option>
            <option value="Withdrawal">Withdrawal</option>
          </select>
        </div>
        <div className="form-group">
          <label>Wallet Address:</label>
          <input type="text" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} />
        </div>
        <button className="save-button" onClick={handleSave}>Save</button>
      </div>
      {notification.show && (
        <div className="notification">
          <span>{notification.message}</span>
          <span className="close-notification" onClick={() => setNotification({ ...notification, show: false })}>&times;</span>
        </div>
      )}
    </div>
  );
};

export default TransactionsModal;
