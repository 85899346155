import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './Accounts.css';
import logo from '../assets/EnterpriseLogo.png';
import AccountModal from './AccountModal'; // Ensure it's imported
const backendUrl = process.env.REACT_APP_BACKEND_URL;


const Accounts = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [accountHolderFilter, setAccountHolderFilter] = useState("");
  const [bookmakerFilter, setBookmakerFilter] = useState("");

  // State for selected status filters (multiple statuses)
  const [statusFilter, setStatusFilter] = useState([]);

  const statusOptions = ['Dead', 'Limit', 'New', 'Trading'];
  const [editingAccount, setEditingAccount] = useState(null);
  const [updatedStatus, setUpdatedStatus] = useState("");

  // Add a state for password visibility toggle
  const [visiblePasswords, setVisiblePasswords] = useState({});

  const fetchAccounts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${backendUrl}/account/list/`);
      if (response.data) {
        setAccounts(response.data);
        setFilteredAccounts(response.data);
      } else {
        console.error('API Error:', response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching Accounts:', error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  const filterAccounts = useCallback(() => {
    let filtered = accounts;

    if (accountHolderFilter) {
      filtered = filtered.filter(
        (account) => account.account_holder === accountHolderFilter
      );
    }

    if (bookmakerFilter) {
      filtered = filtered.filter(
        (account) => account.bookmaker === bookmakerFilter
      );
    }

    if (statusFilter.length > 0) {
      filtered = filtered.filter(
        (account) => statusFilter.includes(account.status)
      );
    }

    setFilteredAccounts(filtered);
  }, [accounts, accountHolderFilter, bookmakerFilter, statusFilter]);

  useEffect(() => {
    filterAccounts();
  }, [accountHolderFilter, bookmakerFilter, statusFilter, filterAccounts]);

  const uniqueAccountHolders = [
    ...new Set(accounts.map((account) => account.account_holder)),
  ];
  const uniqueBookmakers = [
    ...new Set(accounts.map((account) => account.bookmaker)),
  ];

  const handleNavigation = (path) => {
    history.push(path);
  };

  const handleLogout = () => {
    history.push('/logout');
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddAccount = () => {
    openModal();
  };

  const enableEditingStatus = (account) => {
    setEditingAccount(account.account_id);
    setUpdatedStatus(account.status);
  };

  const handleStatusChange = (e) => {
    setUpdatedStatus(e.target.value);
  };

  const saveUpdatedStatus = async (accountId) => {
    try {
      await axios.post(`http://localhost:8080/account/update/${accountId}`, {
        status: updatedStatus,
      });

      setAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account.account_id === accountId
            ? { ...account, status: updatedStatus }
            : account
        )
      );

      setEditingAccount(null);
    } catch (error) {
      console.error('Error saving updated status:', error);
    }
  };

  // Handle multi-select filter for statuses
  const handleStatusFilterChange = (e) => {
    const value = e.target.value;
    setStatusFilter((prevStatusFilter) =>
      prevStatusFilter.includes(value)
        ? prevStatusFilter.filter((status) => status !== value)
        : [...prevStatusFilter, value]
    );
  };

  // Function to toggle password visibility for a given account
  const togglePasswordVisibility = (accountId) => {
    setVisiblePasswords((prevVisiblePasswords) => ({
      ...prevVisiblePasswords,
      [accountId]: !prevVisiblePasswords[accountId], // Toggle the specific account's visibility
    }));
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <img className="dashboard-logo" src={logo} alt="Logo" />
        <h1>The Enterprise</h1>
      </div>
      <div className="dashboard-nav">
        <button className="nav-button" onClick={() => handleNavigation('/dashboard')}>
          Home
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/accounts')}>
          Accounts
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/wallets')}>
          Wallets
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/transactions')}>
          Transactions
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/bets')}>
          Bets
        </button>
        <button className="nav-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <hr className="dashboard-line" />

      {/* Filters and Add Account Button */}
      <div className="filters-add-account-container">
        <div className="filters-container">
          <div className="filter">
            <label>Filter by Account Holder: </label>
            <select
              value={accountHolderFilter}
              onChange={(e) => setAccountHolderFilter(e.target.value)}
            >
              <option value="">All</option>
              {uniqueAccountHolders.map((holder) => (
                <option key={holder} value={holder}>
                  {holder}
                </option>
              ))}
            </select>
          </div>

          <div className="filter">
            <label>Filter by Bookmaker: </label>
            <select
              value={bookmakerFilter}
              onChange={(e) => setBookmakerFilter(e.target.value)}
            >
              <option value="">All</option>
              {uniqueBookmakers.map((bookmaker) => (
                <option key={bookmaker} value={bookmaker}>
                  {bookmaker}
                </option>
              ))}
            </select>
          </div>

          {/* Multi-Select Status Filter */}
          <div className="filter">
            <label>Filter by Status: </label>
            {statusOptions.map((status) => (
              <label key={status} style={{ marginLeft: '10px' }}>
                <input
                  type="checkbox"
                  value={status}
                  checked={statusFilter.includes(status)}
                  onChange={handleStatusFilterChange}
                />
                {status}
              </label>
            ))}
          </div>
        </div>

        {/* Add Account Button */}
        <button className="add-account-button" onClick={handleAddAccount}>
          + Add Account
        </button>
      </div>

      {/* Modal Component should be placed outside the Filters section */}
      <AccountModal isOpen={isModalOpen} onClose={closeModal} />

      {/* Accounts Table */}
      <div className="accounts-container">
        {isLoading ? (
          <p>Loading Accounts...</p>
        ) : (
          <table className="accounts-table">
            <thead>
              <tr>
                <th>Account id</th>
                <th>Bookmaker</th>
                <th>Username</th>
                <th>Password</th>
                <th>Email</th>
                <th>Account Holder</th>
                <th>KYC Passed</th>
                <th>Status</th>
                <th>VPN Used</th>
                <th>VPN Country Used</th>
              </tr>
            </thead>
            <tbody>
              {filteredAccounts.map((account) => (
                <tr key={account.account_id}>
                  <td>{account.account_id}</td>
                  <td>{account.bookmaker}</td>
                  <td>{account.username}</td>

                  {/* Password Field with Toggle Visibility */}
                  <td>
                    {visiblePasswords[account.account_id] ? (
                      <span>{account.password}</span>
                    ) : (
                      <span>******</span> // Masked password
                    )}
                    <button
                      onClick={() => togglePasswordVisibility(account.account_id)}
                    >
                      {visiblePasswords[account.account_id] ? "Hide" : "Show"}
                    </button>
                  </td>

                  <td>{account.email}</td>
                  <td>{account.account_holder}</td>
                  <td>{account.kyc_passed ? 'Yes' : 'No'}</td>

                  {/* Editable Status */}
                  <td>
                    {editingAccount === account.account_id ? (
                      <div>
                        <select value={updatedStatus} onChange={handleStatusChange}>
                          {statusOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <button onClick={() => saveUpdatedStatus(account.account_id)}>
                          Save
                        </button>
                      </div>
                    ) : (
                      <span>
                        {account.status}{' '}
                        <button onClick={() => enableEditingStatus(account)}>
                          Edit
                          </button>
                      </span>
                    )}
                  </td>

                  <td>{account.vpn_used}</td>
                  <td>{account.vpn_country_used}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Accounts;
