import React, { useState } from 'react';
import './WalletModal.css';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const WalletModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        address: '',
        network: '',
        secret_phrase: '',
        wallet_name: '',
        manager: '',
    });

    const [notification, setNotification] = useState({ message: '', show: false });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${backendUrl}/wallet/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (response.ok) {
                setNotification({ message: 'Wallet added successfully!', show: true });
                setTimeout(() => setNotification({ ...notification, show: false }), 10000);
                onClose(); // Close the modal after saving
            } else {
                setNotification({ message: `Error: ${data.error}`, show: true });
                setTimeout(() => setNotification({ ...notification, show: false }), 10000);
            }
        } catch (error) {
            setNotification({ message: `Error: ${error.message}`, show: true });
            setTimeout(() => setNotification({ ...notification, show: false }), 10000);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Add Wallet</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <form className="wallet-form" onSubmit={handleSave}>
                    <label>
                        Address:
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Network:
                        <input
                            type="text"
                            name="network"
                            value={formData.network}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Secret Phrase:
                        <input
                            type="text"
                            name="secret_phrase"
                            value={formData.secret_phrase}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Account Holder:
                        <input
                            type="text"
                            name="wallet_name"
                            value={formData.wallet_name}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Managed By:
                        <input
                            type="text"
                            name="manager"
                            value={formData.manager}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <button type="submit" className="save-button">Save</button>
                </form>
                {notification.show && (
                    <div className="notification">
                        {notification.message}
                        <span className="close-notification" onClick={() => setNotification({ ...notification, show: false })}>&times;</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WalletModal;
