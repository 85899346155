// frontend/src/components/BetsTable.js
import React, { useEffect, useState } from 'react';
import './BetsTable.css';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const BetsTable = () => {
  const [betsByMatch, setBetsByMatch] = useState({});
  const [expandedMatches, setExpandedMatches] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTodaysBets = async () => {
      try {
        const response = await fetch(`${backendUrl}/bets/football/get_today/`);
        if (!response.ok) {
          throw new Error('Failed to fetch today\'s bets');
        }
        const data = await response.json();

        // Group bets by match
        const groupedBets = data.reduce((acc, bet) => {
          if (!acc[bet.match]) acc[bet.match] = [];
          acc[bet.match].push(bet);
          return acc;
        }, {});
        setBetsByMatch(groupedBets);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTodaysBets();
  }, []);

  const toggleMatch = (match) => {
    setExpandedMatches((prevExpandedMatches) => ({
      ...prevExpandedMatches,
      [match]: !prevExpandedMatches[match],
    }));
  };

  if (loading) return <p>Loading today's bets...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="bets-content">
      <h2>Today's Football Bets</h2>
      <table className="bets-table">
        <thead>
          <tr>
            <th>Match</th>
            <th>Market</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(betsByMatch).length > 0 ? (
            Object.keys(betsByMatch).map((match) => (
              <React.Fragment key={match}>
                <tr
                  className="match-row"
                  onClick={() => toggleMatch(match)}
                >
                  <td>{match}</td>
                  <td colSpan="2" className="expand-label">
                    {expandedMatches[match] ? 'Hide Selections' : 'Show Selections'}
                  </td>
                </tr>
                {expandedMatches[match] &&
                  betsByMatch[match].map((bet, index) => (
                    <tr key={index} className="bet-details">
                      <td></td>
                      <td>{bet.market}</td>
                      <td>{bet.price}</td>
                    </tr>
                  ))}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="3">No bets available for today</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BetsTable;
