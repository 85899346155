// frontend/src/components/Dashboard.js

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaPencilAlt, FaSave, FaPlus, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import './Dashboard.css';
import logo from '../assets/EnterpriseLogo.png';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Dashboard = () => {
  const history = useHistory();
  const traders = ['SP', 'EI', 'NB', 'AL', 'JK', 'XT'];

  // State for stakes and PnL data
  const [bookmakers, setBookmakers] = useState([]);
  const [editableColumns, setEditableColumns] = useState({});
  const [isEditingPnl, setIsEditingPnl] = useState(false);
  const [weeklyPnlData, setWeeklyPnlData] = useState({
    privateWallets: '',
    usableWallets: '',
    withdrawableAccounts: '',
    unusableAccounts: '',
    pendingWithdrawals: '',
    totalWeeklyPnl: '',
    totalPnl: '',
  });

  // Fetch initial data
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const pnlResponse = await axios.get(`${backendUrl}/dashboard/pnl/latest`);
        const stakesResponse = await axios.get(`${backendUrl}/dashboard/stakes/latest`);

        // Exclude `_id` from the PnL data before setting it
        const { _id, ...pnlData } = pnlResponse.data.latest_pnl || {};
        setWeeklyPnlData(pnlData);

        // Process stakes data to match the format of the main table
        const stakesData = stakesResponse.data.latest_stakes?.stakes || {};
        const formattedBookmakers = Object.keys(stakesData).map((bookmakerName) => ({
          name: bookmakerName,
          ...stakesData[bookmakerName],
        }));

        setBookmakers(formattedBookmakers);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  // Save the bookmakers data to the backend
  const saveBookmakers = async () => {
    try {
      const payload = {
        date: new Date().toISOString().split('T')[0],
        stakes: bookmakers.reduce((acc, bookmaker) => {
          acc[bookmaker.name] = traders.reduce((obj, trader) => {
            obj[trader] = bookmaker[trader] || 0;
            return obj;
          }, {});
          return acc;
        }, {}),
      };
      await axios.post(`${backendUrl}/dashboard/stakes/update`, payload);
      setEditableColumns({}); // Reset edit mode for all columns
    } catch (error) {
      console.error("Error saving bookmakers data:", error);
    }
  };

  // Save weekly PnL data
  const saveWeeklyPnl = async () => {
    try {
      const payload = { date: new Date().toISOString().split('T')[0], ...weeklyPnlData };
      await axios.post(`${backendUrl}/dashboard/pnl/update`, payload);
      setIsEditingPnl(false);
    } catch (error) {
      console.error("Error saving weekly PnL data:", error);
    }
  };

  // Handler functions
  const handleNavigation = (path) => history.push(path);
  const toggleEditColumn = (column) => setEditableColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  const cancelWeeklyPnlEdit = () => setIsEditingPnl(false);
  const handlePnlFieldChange = (e, field) => setWeeklyPnlData((prev) => ({ ...prev, [field]: e.target.value }));

  const addNewRow = () => {
    const newRow = { name: `Bookmaker ${bookmakers.length + 1}` };
    traders.forEach((trader) => (newRow[trader] = ''));
    setBookmakers((prev) => [...prev, newRow]);
  };

  const calculateTotals = () => {
    const totals = {};
    traders.forEach((trader) => {
      totals[trader] = bookmakers.reduce((acc, bookmaker) => acc + parseFloat(bookmaker[trader] || 0), 0).toFixed(2);
    });
    return totals;
  };
  const totals = calculateTotals();

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <img className="dashboard-logo" src={logo} alt="Logo" />
        <h1>The Enterprise</h1>
      </div>

      <div className="dashboard-nav">
        <button className="nav-button" onClick={() => handleNavigation('/dashboard')}>Home</button>
        <button className="nav-button" onClick={() => handleNavigation('/accounts')}>Accounts</button>
        <button className="nav-button" onClick={() => handleNavigation('/wallets')}>Wallets</button>
        <button className="nav-button" onClick={() => handleNavigation('/transactions')}>Transactions</button>
        <button className="nav-button" onClick={() => handleNavigation('/bets')}>Bets</button>
        <button className="nav-button" onClick={() => handleNavigation('/logout')}>Logout</button>
      </div>
      <hr className="dashboard-line" />

      <div className="dashboard-content">
        {/* Main Stakes Table */}
        <div className="large-table-container">
          <table className="large-table">
            <thead>
              <tr className="tab-row">
                <th colSpan={traders.length + 2}>
                  <button className="tab-button active">Stakes</button>
                </th>
              </tr>
              <tr>
                <th>
                  Bookmakers <FaPlus className="add-row-icon" onClick={addNewRow} title="Add new row" />
                </th>
                {traders.map((trader) => (
                  <th key={trader}>
                    <div className="trader-header">
                      {trader}
                      {editableColumns[trader] ? (
                        <FaSave onClick={saveBookmakers} className="edit-save-icon" title="Save changes" />
                      ) : (
                        <FaPencilAlt onClick={() => toggleEditColumn(trader)} className="edit-save-icon" title="Edit column" />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bookmakers.map((bookmaker, index) => (
                <tr key={index}>
                  <td>{bookmaker.name}</td>
                  {traders.map((trader) => (
                    <td key={trader}>
                      {editableColumns[trader] ? (
                        <input
                          type="text"
                          value={bookmaker[trader]}
                          onChange={(e) => setBookmakers((prev) => {
                            const updated = [...prev];
                            updated[index][trader] = e.target.value;
                            return updated;
                          })}
                        />
                      ) : (
                        `₿ ${bookmaker[trader] || 0}`
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              <tr className="total-row">
                <td><strong>Total</strong></td>
                {traders.map((trader) => <td key={trader}><strong>₿ {totals[trader]}</strong></td>)}
              </tr>
            </tbody>
          </table>
        </div>

        {/* Right Side Small Tables */}
        <div className="small-tables-container">
          {/* Weekly PnL Table */}
          <div className="small-table">
            <h3>
              Weekly PnL
              {isEditingPnl ? (
                <div className="pnl-action-icons">
                  <FaSave onClick={saveWeeklyPnl} className="icon-button" title="Save all changes" />
                  <FaTimes onClick={cancelWeeklyPnlEdit} className="icon-button" title="Cancel edit" />
                </div>
              ) : (
                <FaPencilAlt onClick={() => setIsEditingPnl(true)} className="icon-button" title="Edit all fields" />
              )}
            </h3>
            <table>
              <tbody>
                {Object.keys(weeklyPnlData).map((key) => (
                  <tr key={key}>
                    <td>{key.replace(/([A-Z])/g, ' $1')}</td>
                    <td>
                      {isEditingPnl ? (
                        <input
                          type="text"
                          value={weeklyPnlData[key]}
                          onChange={(e) => handlePnlFieldChange(e, key)}
                        />
                      ) : (
                        `₿ ${weeklyPnlData[key]}`
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
