import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PendingTransactionsModal.css';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const PendingTransactionsModal = ({ isOpen, onClose }) => {
  const [flaggedTransactions, setFlaggedTransactions] = useState([]);
  const [traders, setTraders] = useState([]);
  const [walletAddresses, setWalletAddresses] = useState([]);
  const [selectedTrader, setSelectedTrader] = useState('');
  const [selectedWalletAddress, setSelectedWalletAddress] = useState('');
  const [editingTransactionId, setEditingTransactionId] = useState(null);
  const [editedSource, setEditedSource] = useState('');
  const [loading, setLoading] = useState(false); // Loading state for fetch request

  useEffect(() => {
    if (isOpen) {
      fetchFlaggedTransactions();
    }
  }, [isOpen]);

  const fetchFlaggedTransactions = async () => {
    setLoading(true); // Set loading to true when starting to fetch
    try {
      const response = await axios.get(`${backendUrl}/wallet/transactions/query/flagged`);
      if (response.data.success && Array.isArray(response.data.result)) {
        const transactions = response.data.result;
        setFlaggedTransactions(transactions);

        const uniqueTraders = [...new Set(transactions.map(t => t.trader))];
        const uniqueWalletAddresses = [...new Set(transactions.map(t => t.wallet_address))];
        setTraders(uniqueTraders);
        setWalletAddresses(uniqueWalletAddresses);
      } else {
        console.error('API Error:', response.data);
        setFlaggedTransactions([]);
      }
    } catch (error) {
      console.error('Error fetching flagged transactions:', error);
      setFlaggedTransactions([]);
    } finally {
      setLoading(false); // Set loading to false when fetch is complete
    }
  };

  const handleEditClick = (transactionId, currentSource) => {
    setEditingTransactionId(transactionId);
    setEditedSource(currentSource);
  };

  const handleSaveClick = async (transactionId) => {
    try {
      await axios.post(`${backendUrl}/wallet/transactions/pending/ammend`, {
        id: transactionId,
        source: editedSource,
      });
      setEditingTransactionId(null);
      fetchFlaggedTransactions(); // Refresh data after saving
    } catch (error) {
      console.error('Error updating transaction source:', error);
    }
  };

  const handleCancelClick = () => {
    setEditingTransactionId(null);
    setEditedSource('');
  };

  const filteredFlaggedTransactions = flaggedTransactions.filter((transaction) => {
    const matchesWallet = selectedWalletAddress ? transaction.wallet_address === selectedWalletAddress : true;
    const matchesTrader = selectedTrader ? transaction.trader === selectedTrader : true;
    return matchesWallet && matchesTrader;
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h2>Flagged Transactions</h2>

        {/* Filters Section */}
        <div className="filters">
          <select value={selectedWalletAddress} onChange={(e) => setSelectedWalletAddress(e.target.value)}>
            <option value="">All Wallet Addresses</option>
            {walletAddresses.map((address, index) => (
              <option key={index} value={address}>{address}</option>
            ))}
          </select>

          <select value={selectedTrader} onChange={(e) => setSelectedTrader(e.target.value)}>
            <option value="">All Traders</option>
            {traders.map((trader, index) => (
              <option key={index} value={trader}>{trader}</option>
            ))}
          </select>

          {/* Loading Button */}
          <button className="refresh-button" onClick={fetchFlaggedTransactions} disabled={loading}>
            {loading ? 'Loading...' : 'Refresh'}
          </button>
        </div>

        <div className="flagged-transactions">
          <table className="transactions-table">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Trader</th>
                <th>Wallet Address</th>
                <th>Source</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(filteredFlaggedTransactions) && filteredFlaggedTransactions.map((transaction) => (
                <tr key={transaction.tx_id}>
                  <td>{transaction.tx_id}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.trader}</td>
                  <td>{transaction.wallet_address}</td>
                  <td>
                    {editingTransactionId === transaction.tx_id ? (
                      <input
                        type="text"
                        value={editedSource}
                        onChange={(e) => setEditedSource(e.target.value)}
                      />
                    ) : (
                      transaction.source
                    )}
                  </td>
                  <td>
                    {editingTransactionId === transaction.tx_id ? (
                      <>
                        <button onClick={() => handleSaveClick(transaction.tx_id)}>Save</button>
                        <button onClick={handleCancelClick}>Cancel</button>
                      </>
                    ) : (
                      <button onClick={() => handleEditClick(transaction.tx_id, transaction.source)}>Edit</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PendingTransactionsModal;
